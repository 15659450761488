.loginContainer {
    box-sizing: border-box;

    .loginContainer_body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100svh;

        .loginContainer_body_form {
            border: 1px solid #fff;
            padding: 20px;
            border-radius: 10px;
            background: #fff;
            color: #000;

            .loginContainer_body_form_logo{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .input-container_label{
                margin-top:1rem;
            }

            .input-container{
                // margin:1rem auto;

                label{
                    display: block;
                    font-size:1rem;
                }

                input{
                    width:100%;
                    border:2px solid #f0f0f0;
                    border-radius: 4px;
                    padding:0.5rem;
                    margin-top:0.325rem;

                    &#password{
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &::placeholder{
                        color: rgba(0,0,0,0.5);
                        font-size:0.75rem;
                        font-style:italic;

                    }
                }

                .showPwdIcon{
                    cursor: pointer;
                    padding:0.5rem;
                    border: 2px solid #f0f0f0;
                    border-radius: 4px;
                    margin-top:0.325rem;
                    margin-right:0;
                    border-left:none;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

            }

            .errorMessage{
                color:red;
                font-size:0.75rem;
                font-style:italic;
                text-align:center;
            }

            .loginContainer_submit {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 1rem auto;

                input {
                    background: #897F77;
                    color: #fff;
                    border-radius: 4px;
                    font-weight: 500;
                    border:none;
                    padding:0.5rem;
                }

            }

            .returnHome{
                text-align: center;

                a{
                    text-decoration: none;
                    color:#000;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}