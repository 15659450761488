$blackColor: #1F212A;
$whiteColor: #F4F4F1;
$textHighlightColor: #BAB073;
$lightThemeColor: #E0E0E0;
$highlightColor: #BAB073;
$color3: #897F77;
$color5: #8D886D;

body {
  height: 100%;
  background: $blackColor !important;
  color: $whiteColor !important;
  &.white-content {
    background: $lightThemeColor !important;
    color: $blackColor !important;
    .about-section{
      background: $whiteColor !important;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// social Icons
.fb-icon {
  color: #3b5998;
}
.instagram-icon {
  color: #bc2a8d;
}
.twitter-icon {
  color: #55acee;
}
.linkedin-icon {
  color: #0077b5;
}
.github-icon {
  color: $whiteColor;
}
.footer_social-icon {
  &.github-icon {
    color: $blackColor;
    &:hover {
      color: $blackColor;
    }
  }
}
// Hover effects for Social Icons
a {
  &:hover {
    &.fb-icon {
      color: #3b5998;
    }
    &.instagram-icon {
      color: #bc2a8d;
    }
    &.twitter-icon {
      color: #55acee;
    }
    &.linkedin-icon {
      color: #0077b5;
    }
    &.github-icon {
      color: $whiteColor;
    }
  }
}
// box-shadow effect
.box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.white-content {
  .social_elembef {
    background: $blackColor !important;
  }
  .footer-contact {
    border-top: 1px solid $blackColor !important;
    border-bottom: 1px solid $blackColor !important;
  }
  .github-icon {
    color: $blackColor !important;
    &.hover {
      color: $blackColor !important;
    }
  }
  .footer_social-icon {
    border: 1px solid $blackColor !important;
  }
  .email-contact {
    a {
      color: $blackColor !important;
    }
  }
  #crow {
    color: $blackColor !important;
  }
  .header-section{
    background:$whiteColor;
    color:$blackColor;
  }
}
section {
  height: 100vh;
}
.main-container {
  height: 100vh;  //590px
  padding-bottom: 1rem;
  position: relative;
  .name_design {
    font-size: 2.625rem;
    width: 500px;
    .text_highlighter {
      font-weight: 600;
      color: $textHighlightColor;
    }
    .intro_about {
      font-size: 1.25rem;
      margin-top: 0.625rem;
    }
  }
  .imageContainer {
    height: 310px;
    width: 250px;
    background: rgba(255, 255, 255, 0.9);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 1px solid #e0e0e0;
    img {
      position: absolute;
      top: -25px;
      left: -95px;
    }
  }
  .social_elem {
    position: absolute;
    top: 12%;
    right: 0px;
    font-size: 1.875rem;
    margin-right: 1.875rem;
    .social_elembef {
      height: 140px;
      width: 2px;
      background: $whiteColor;
      margin-left: 1rem;
    }
  }
  .tech_section {
    margin-top: 5rem;
    padding: 0 0.625rem;
    .techStack {
      padding-left: 0.625rem;
      background: $whiteColor;
      width: 800px;
      height: 120px;
      border-radius: 6px;
    }
  }
}
.greetings_elem {
  .greetings_wish {
    font-size: 20px;
    margin-bottom: 0px;
    display: inline-block;
    margin-left: 10px;
  }
  .wish {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; /* Never stop waving :) */
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
    font-size: 1.25rem;
    margin-left: 0.313rem;
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

@media (max-width: 575.98px) {
  html{
    font-size: 14px;
  }
  .main-container {
    height: 780px !important;
    .name_design {
      width: 100%;
    }
    .social_elem {
      .social_elembef {
        height: 100px;
        margin-left: 0.625rem;
      }
    }
    #crow {
      font-size: 1.875rem;
    }
    .tech_section {
      padding: 0.625rem;
    }
  }
  .main-icon {
    font-size: 1.5rem;
    padding-right: 0.938rem;
  }
  .card.card1 img {
    width: 100%;
  }
  .image-sec {
    padding-top: 0.625rem;
  }
  .main-nav {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .flex-row_first {
    order: 2;
  }
  .flex-row_second {
    order: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 1.875rem !important;
  }
  .tech_section {
    width: 100%;
    overflow: hidden;
    margin-top: 1.875rem !important;
    .techStack {
      width: 100% !important;
      height: 80px !important;
      .tech_icons {
        height: 45px;
        width: 35px;
      }
    }
  }
  .social_elem {
    margin-right: 0.625rem !important;
  }
  .name_design {
    text-align: center;
  }
  .text_highlighter {
    font-size: 1.875rem;
    text-align: center;
  }
  #crow {
    font-size: 1.875rem;
    display: none;
  }
  .intro_about {
    text-align: center;
  }
  .footer-contact {
    margin: 1.875rem !important;
    flex-direction: column;
  }
  .social-contact {
    margin-top: 0.625rem;
  }
  .about-section {
    .image-thumbnail {
      margin-left: auto !important;
      margin: 0rem auto;
      margin-bottom: 1.25rem;
    }
  }
}
.type {
  width: 19ch;
  float: left;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  animation: type 3s steps(20) 2s infinite alternate;
  letter-spacing: 0.138rem;
  &::after {
    background: $blackColor;
  }
}
#crow {
  float: left;
  color: $whiteColor;
  font-size: 2.625rem;
  animation: crow 0.5s linear 0s infinite;
}
@keyframes type {
  from {
    width: 19ch;
  }
  to {
    width: 0ch;
  }
}
@-webkit-keyframes type {
  from {
    width: 19ch;
  }
  to {
    width: 0ch;
  }
}
@keyframes crow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Header Section

.header-section{
  width: 100%;
  background: $blackColor;
  position:sticky;
  top:0;
  z-index:999;

  // Theme Changer

  .themeChanger {
    -webkit-appearance: none;
    position: relative;
    width: 40px;
    height: 20px;
    background-color: $textHighlightColor;
    background-size: cover;
    border-radius: 50px;
    outline: none;
    margin-left: 0.625rem;
    margin-right:0.625rem;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: navy;
      border-radius: 50px;
    }
    &:checked {
      background-color: $textHighlightColor;
      &:before {
        transform: translate(100%);
      }
    }
  }
}

// footer section
.footer-section {
  .footer-contact {
    border-top: 1px solid $whiteColor;
    border-bottom: 1px solid $whiteColor;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1.875rem 9.375rem;
    padding: 0.938rem 0rem;
  }
  .footer_social-icon {
    margin-left: 0.625rem;
    font-size: 1rem;
    border: 1px solid $whiteColor;
    border-radius: 50%;
    padding: 0.313rem 0.438rem;
    background: $whiteColor;
  }
  .email-contact {
    a {
      text-decoration: none;
      color: $whiteColor;
    }
  }
}

// about section

.about-section {
  background-color: #e0e0e0;
  padding: 1.25rem 0rem;
  color: $blackColor;
  .about-heading {
    text-decoration: underline;
    margin-bottom: 1.563rem;
  }
  .image-thumbnail {
    border: 1px solid $blackColor;
    border-radius: 50%;
    width: 270px;
    height: 270px;
    position: relative;
    overflow: hidden;
    margin-left: 12.5rem;
    box-shadow: $blackColor 0px 2px 8px 0px;
    .about-pic {
      height: 320px;
      width: 320px;
      position: absolute;
      bottom: 0px;
      left: -20px;
    }
  }
  .resumetag {
    border: 1px solid $highlightColor;
    border-radius: 6px;
    padding: 0.313rem;
    color: $whiteColor;
    background: $highlightColor;
    text-decoration: none;
  }
}

.section404 {
.text404{
  font-weight:900;
  font-size: 8rem;
}
.subText404 {
  font-weight:600;
  font-size: 1.5rem;
  
  &:hover{
    cursor:pointer;
    text-decoration: underline;
    color:#fff
  }
}
}

.editForm {
  padding:2rem 5rem;
  border: 1px solid $whiteColor;
  border-radius:10px;

  input[type=text] {
    outline: none;
    height: 5vh;
    border-radius: 4px;
    padding: 0.5rem;
  }

  textarea{
    outline: none;
    min-width: fit-content;
    border-radius: 4px;
    padding: 0.5rem;
  }

  input[type=submit] {
    outline: none;
    background:$highlightColor;
    color:$whiteColor;
    padding:0.5rem;
    border-radius:8px;

    &:hover{
      cursor:pointer;
      background:$whiteColor;
      color:$highlightColor;
    }
  }
}

.editHeader {
  button{
    outline: none;
    border:none;
    &:hover{
      background:$highlightColor;
      color:$whiteColor;
    }
  }
}
